var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.StatusApi},on:{"update:active":function($event){_vm.StatusApi=$event}}}),_c('a-row',{attrs:{"type":"flex","justify":"center"}},[(_vm.type !== 'all')?_c('a-col',{attrs:{"span":24}},[_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{staticClass:"mb-3",attrs:{"span":5,"offset":"2"}},[(_vm.StatusUser === 'ADMIN')?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.CreateOfficer}},[_vm._v("สร้างเจ้าหน้าที่")]):_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.CreateAdmin}},[_vm._v("สร้างแอดมิน")])],1),_c('a-col',{attrs:{"span":7,"offset":"8"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"ค้นหา"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1):_vm._e(),_c('a-col',{staticClass:"mt-5",attrs:{"xs":24,"md":20}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.DataTable,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"hide-default-footer":""},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.activeFlag",fn:function(ref){
var item = ref.item;
return [(item.activeFlag === 1)?_c('a-tag',{attrs:{"color":"green"}},[_vm._v("อนุมัติ")]):(item.activeFlag === 0)?_c('a-tag',{attrs:{"color":"red"}},[_vm._v("ปิดการใช้งาน")]):_c('a-tag',{attrs:{"color":"red"}},[_vm._v("รออนุมัติ")])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-switch',{attrs:{"dense":""},on:{"change":function($event){return _vm.ChangeStatus(item)}},model:{value:(item.check),callback:function ($$v) {_vm.$set(item, "check", $$v)},expression:"item.check"}})],1)]}},{key:"item.edit3",fn:function(ref){
var item = ref.item;
return [_c('a-button',{on:{"click":function($event){return _vm.detail(item)}}},[_vm._v("รายละเอียด")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('a-icon',{staticClass:"pl-0",attrs:{"type":"delete"},on:{"click":function($event){return _vm.deleteUser(item)}}})]}}],null,true)})],1),_c('div',{staticClass:"text-center pt-2 pb-3"},[_c('v-pagination',{attrs:{"light":"","total-visible":7,"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1),_c('Modal',{attrs:{"props":_vm.PropModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }